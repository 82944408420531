<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header fs-14" v-if="error">
            <slot name="header">
              {{ error }}
            </slot>
          </div>
          <div class="modal-body" v-else>
            <slot name="body">
              <qrcode-stream @decode="onDecode" @init="onInit" ></qrcode-stream>
            </slot>
            <div style="font-size:16px; color: #383838; font-weight: 700; margin-top: 10px;">보낼 주소</div>
            <input type="text" v-model="qrAddress" placeholder="QR 코드를 스캔하거나 주소 입력하세요." style="border-bottom: 1px solid #e3e3e3; height: 24px; width: 100%;  margin-bottom: 10px; border: 1px solid #adb5bd;"/>
            <div @click="sendToken()" style="font-size: 16px !important; display : flex; justify-content: center; align-items : center; height: 24px; background-color: #1963ff; color: #fff;">보내기</div>
          </div>
          <!-- <div v-for="(block, i) in eventInfo" :key="i">
            <div>{{block.blockHash}}</div>
            <div>{{block.eventHash}}</div>
            <div>{{block.transaction_hash}}</div>
            <div>{{block.definition_id}}</div>
          </div> -->
          <!-- <div v-for="(block, i) in initEventInfo" :key="i">
            <div>{{block.blockHash}}</div>
            <div>{{block.eventHash}}</div>
            <div>{{block.transaction_hash}}</div>
          </div> -->
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn modal-default-button" @click="$emit('close')">
                닫기
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import localKeystore from '@/local/localKeystore.js'
import {buffToHex} from '@/crypto/cryptoutil.js'
import {postData} from '@/api/serverApi';

export default {
  props:{
    initEventInfo:Array
  },
  data(){
    return {
      error: '',
      result: '',
      eventInfo:this.initEventInfo,
      qrAddress: '',
      user:''
    }
  },
  mounted() {
    console.log(['mounted', process.env.VUE_APP_API_URL]);
    this.user = localStorage.getItem('user-info');
    this.$emit("transResult", 'fail');
  },
  methods:{
    onDecode (result) {
      alert(result);
      this.qrAddress = result;
      // this.$store.commit(this.$types.QR_ADDRESS, result);
      // this.$emit('close')
    },
    sendToken (){
      if(this.qrAddress == ''){
        alert("주소를 입력해주세요.");
      }else{
        if (confirm(this.qrAddress +"의 주소에 "+ this.eventInfo[0].event_name + "NFT를 전송하시겠습니까?")) {
          const pinNumberKey = 'WNS_PIN_NUMBER'; //키값으로 로컬 스토리지에 저정
          const pinNumber = sessionStorage.getItem(pinNumberKey);
          console.log('pinNumber : ' +pinNumber);

          const user = JSON.parse(this.user);
          console.log('user : ' +user);

          localKeystore.getPrivateKey(pinNumber).then( retunrpk => {
            console.log(['getPrivateKey', buffToHex(retunrpk)]);

            const data = {
              eventInfo : JSON.stringify(this.eventInfo),
              qrAddress : this.qrAddress,
              privateKey : buffToHex(retunrpk),
              user_code : user.user_code,
            };

            postData('/user/postTransactionTransferToken', data).then( res => {
              console.log(['postTransactionTransferToken', res, res.data.success]);
              console.log(['postTransactionTransferToken', res, res.data]);
              if (res.data.success) {
                alert("전송되었습니다.");
                this.$emit("transResult", 'success');
              }else{
                alert(res.data.errorMassage);
                this.$emit("transResult", 'fail');
              }
            })

          }).catch( err => {
            console.log(err);
          })
        }
      }
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "You need to grant camera access permisson."
        } else if (error.name === 'NotFoundError') {
          this.error = "No camera on this device."
        } else if (error.name === 'NotSupportedError') {
          this.error = "Secure context required (HTTPS, localhost)."
        } else if (error.name === 'NotReadableError') {
          this.error = "Is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "Installed cameras are not suitable."
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "Stream API is not supported in this browser."
        }
      }
    },
  }
}
</script>

