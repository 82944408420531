<template>
	<div id="wallet" class="wallet">
		<div class="wallet_list">
			<div class="top">
				<span style="float: left; display: block;"><h2>총 보유 금액</h2></span>
				<span style="float: right; display: block;">
					<button @click="showAddressQrModal=true" style="color: #fff;">
						<!-- <img src="../assets/common/ico-16-download-white.svg" alt="받기" style="vertical-align: middle;" /> -->
						지갑으로 받기
					</button>
				</span>
				<!-- <h2>총 보유 금액</h2> -->
				<div>
					<div>
						<span>KRW</span>
						<p style="margin-bottom: 0;">{{ totalCountKrw.toFixed(0) | makeComma }}</p>
						<a href="javascript:"><img src="../assets/common/ico-16-refresh-w.svg" alt="새로고침" @click="getWallet()"/></a>
					</div>
					<p>ETH {{ totalCountETH.toFixed(4) }}</p>
				</div>
			</div>
			
			<div class="list swiper-container" style="padding-bottom: 50px; margin-left: 24px; ">
				<swiper class="swiper-wrapper" :options="swiperOptions">
					<swiper-slide class="swiper-slide">
						<div class="qr">
							<!-- <p class="img"><img src="../assets/common/qr.svg" alt="qr" /></p> -->
							<span class="txt">3Ddfex34tDF33ddjTc.</span>
						</div>
						<div class="card">
							<div class="top">
								<!-- <p>내 꿈이닷! <span>(신한 123-546-345)</span></p>
								<router-link to="/wallet-viewitem"><img src="../assets/common/ico-24-settings.svg" alt="설정" /></router-link> -->
							</div>
							<div class="money">
								<div>
									<span>WT</span>
									<p>54,000,500</p>
									<a href="javascript:"><img src="../assets/common/ico-16-refresh.svg" alt="새로고침" /></a>
								</div>
								<p>KRW 54,000,500</p>
							</div>
							<ul>
								<li><a href="javascript:"><img src="../assets/common/ico-16-upload.svg" alt="보내기" /><span>보내기</span></a></li>
								<li><a href="javascript:"><img src="../assets/common/ico-16-download.svg" alt="받기" /><span>받기</span></a></li>
							</ul>
						</div>
					</swiper-slide>
					<swiper-slide class="swiper-slide" v-for="(event, i) in eventList" :key="i">
						<div class="qr">
							<!-- <span class="txt">{{event.event.event_name}}</span> -->
							<span class="right" v-if="event.isFinal">
								<button class="qr-button" @click="qrModal(event.event.event_hash,'','',event.event.definition_id, event.event.event_name,'event','','')">
									<!-- <img src="../assets/common/ico-16-upload-white.svg" alt="보내기" style="vertical-align: middle;" /> -->
									보내기
								</button>
							</span>
							<span class="right" v-if="!event.isFinal">
								<button class="qr-button" @click="sendAlert()">
									<!-- <img src="../assets/common/ico-16-upload-white.svg" alt="보내기" style="vertical-align: middle;" /> -->
									보내기
								</button>
							</span>
						</div>
						<div class="eventImage">
							<div class="bottom detail-image">
								<ul style="margin-bottom: 0; padding-left: 0;">
									<li class="block-wrap" :class="'block-' + event.eventBlockList.length" v-for="(block, i) in event.eventBlockList" :key="i">
										<!-- <a class="hint-wrap" v-if="block.block_find" @click="qrModal(block.event_hash,block.block_hash, block.transaction_hash,event.event.definition_id,event.event.event_name,'block', block.block_records_idx, block.block_level)" href="javascript:" style="margin-left: 5px; margin-top: 5px;">
											<img src="../assets/common/ico-16-upload-white.svg"/>
											<span style="color: #fff;">블록 보내기</span>
										</a> -->
										<div class="img-wrap" >
											<img :src="imageSrc+event.event.image_path + (i+1) + '.' + event.event.image_ext" alt="" :class="!block.block_find ? 'disable': ''" />
										</div>
									</li>
								</ul>
							</div>
						</div>
					</swiper-slide>
					
				</swiper>
				<div class="swiper-pagination"></div>
			</div>
		</div>

		<div class="menu_list">
			<ul>
				<li>
					<!--<router-link to="./wallet-list">-->
					<a href="javascript:">
						<div>
							<p><img src="../assets/common/ico-24-paper.svg" alt="이용 내역" /><span>이용 내역</span></p>
							<p><img src="../assets/common/ico-22-arrow.svg" alt="이동" /></p>
						</div>
					</a>
				</li>
				<li>
					<a href="javascript:">
						<div>
							<p><img src="../assets/common/ico-24-webcam.svg" alt="부가 서비스" /><span>부가 서비스</span></p>
							<p><img src="../assets/common/ico-22-arrow.svg" alt="이동" /></p>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<qrcode-modal-vue  v-if="showModal" @transResult="transResultFunc" @close="qrModalClose" :initEventInfo="eventInfo"></qrcode-modal-vue>
		<address-qr-modal-vue  v-if="showAddressQrModal"  @close="showAddressQrModal=false"></address-qr-modal-vue>
		
	</div>
</template>


<script>
import {getEthPrice} from '@/api/serverApi';
import {postData} from '@/api/serverApi';
import { Swiper,directive, SwiperSlide } from 'vue-awesome-swiper'
import QrcodeModalVue from '@/components/wallet/QrcodeModalVue'
import AddressQrModalVue from '@/components/wallet/AddressQrModalVue'


export default {
    name: "walletView",
	components: {
		Swiper,
		SwiperSlide,
		QrcodeModalVue,
		AddressQrModalVue
	},
	directives: {
		swiper: directive
	},
	data() {
		return {
			imageSrc: '',
			swiperOptions: {
				slidesPerView: 1.2,
				loop: false,
				spaceBetween: 24,
				slidesOffsetAfter : 0, 
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
				},
			},
			eventList : [],
			totalCountKrw : 0,
			totalCountETH : 0,
			eth_price: 0,
			showModal: false,
			showAddressQrModal: false,
			eventInfo:[],
			transResultParam:'fail'
		}
	},
	mounted() {
		console.log(['mounted', process.env.VUE_APP_API_URL]);
		this.user = localStorage.getItem('user-info');
		console.log(['user', this.user]);
		// let IMG_URL = 'https://api.wonhago.com';
		let IMG_URL = process.env.VUE_APP_API_URL;

		if (process.env.NODE_ENV === 'development') {
			// IMG_URL = 'http://localhost:3004';
			IMG_URL = 'https://ipapi.wonhago.com';
		}

		this.imageSrc = IMG_URL;

		this.getWallet();

	},
	methods: {
		Movemove() {
			this.$router.push('/join');
		},
		getWallet() {//지갑 정보 가져오기
			this.totalCountKrw = 0;
			this.totalCountETH = 0;
			this.eth_price = 0;

			//이더리움 가격 가저오기 - https://api.upbit.com/v1/ticker?markets=KRW-ETH
			//async 동작
			getEthPrice().then( res => {
				console.log(['res', res]);
				if (res && res.data) {
				console.log(['getEthPrice', res.data[0], res.data[0].trade_price]);
				this.eth_price = res.data[0].trade_price;
				}
			});

			let userInfo = null;
			if (this.user) {
				userInfo = JSON.parse(this.user);

			}
			const params = {
				user_code : userInfo ? userInfo.user_code : null
			}

			postData('/user/getbalance', params).then( res => {
				console.log(['getbalance res, res.data', res, res.data]);
				console.log(['getbalance res.data.success', res, res.data.success]);
				if (res.data.success) {
					this.eventList = res.data.events_list;
					
					console.log(['this.eventList', this.eventList]);
					console.log(['this.eventList.length', this.eventList.length]);
					this.eventList.forEach(event => {
						
						console.log(['event.entry.cnt', event.entry.cnt]);
						console.log(['event.event.payment / event.entry.cnt', (event.event.payment / event.entry.cnt)]);
						console.log(['event.event.payment / this.eth_price', (event.event.payment / this.eth_price)]);

						this.totalCountKrw += (event.event.payment / event.entry.cnt);
						this.totalCountETH += (event.event.payment / event.entry.cnt / this.eth_price);
					});
				}
			})
		},
		qrModal(eventHash, blockHash, transaction_hash, definition_id, event_name, sendType, block_records_idx, block_level ){
			this.eventInfo = [];
			if(sendType == 'block'){
				this.eventInfo.push({'eventHash':eventHash,'blockHash':blockHash, 'definition_id':definition_id, 'transaction_hash':transaction_hash, 'event_name':event_name, 'block_records_idx' : block_records_idx, 'block_level' : block_level });
			}else{
				this.eventList.forEach(event => {
					console.log(['event.event.event_hash', event.event.event_hash]);
					console.log(['eventHash', eventHash]);
					if(event.event.event_hash == eventHash){
						event.eventBlockList.forEach(eventBlock => {
							this.eventInfo.push({'eventHash':eventBlock.event_hash,'blockHash':eventBlock.block_hash, 'definition_id':definition_id,  'transaction_hash':eventBlock.transaction_hash, 'event_name':event_name, 'block_records_idx':eventBlock.block_records_idx, 'block_level' : eventBlock.block_level});
						});
					}
				});
			}
			console.log(['this.eventInfo', this.eventInfo]);
			this.showModal = true;
		},
		transResultFunc(data){
			console.log(['data', data]);
			this.transResultParam = data;
		},
		qrModalClose(){
			this.showModal=false
			if(this.transResultParam == 'success'){
				this.getWallet();
			}
		},
		sendAlert(){
			alert("모든 조각을 찾아야 전송이 가능합니다.");
		}
	}
}
</script>