import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue';
// import Detail from '../views/Detail.vue';
import Wallet from '../views/Wallet.vue';
import WalletList from '../views/WalletList.vue';
import WalletMake from '../views/WalletMake.vue';
import WalletViewItem from '../views/WalletViewItem.vue';
import Join from '../views/Join.vue';
import Login from '../views/Login.vue';
import MyHome from '../views/MyHome.vue';
import MyInfo from '../views/MyInfo.vue';
import Pin from '../views/Pin.vue';
import GoodsHome from '../views/GoodsHome.vue';
import Find from '../views/Find.vue';
import Share from '../views/Share.vue';

import localKeystore from '../local/localKeystore.js'

import { del } from 'idb-keyval';
const elliptic = require('elliptic');
const ec = new elliptic.ec('secp256k1');
const { keccak_256 } =  require('js-sha3');
const { map, splitEvery }  = require('rambda');
import { postData } from '@/api/serverApi';

const deviceKey = 'device-info';
const userKey = 'user-info';
const KeystoreKey = 'KeystoreKey';

const hexStringToArray = (hexString) => {
  return map((hex) => parseInt(hex, 16))(
    splitEvery(2)(hexString)
  );
};

Vue.use(VueRouter);

const pinNumberKey = 'WNS_PIN_NUMBER'; //키값으로 로컬 스토리지에 저정
const authAddress = (to, from, next) => {
  localKeystore.getAddress().then((address) => {
    if (!address) { next('/join'); } else { next() }
  })
}
const cofirmKeyRemoval = async (to, from, next) => {
  localKeystore.getAddress().then( async (address) => {
    console.log(["cofirmKeyRemoval address", address]);
    if (!address) { 
      console.log(["cofirmKeyRemoval !address if"]);
      next(); 
    } else {
      console.log(["cofirmKeyRemoval !address else"]);
      if (confirm('저장된 모든 키가 삭제 됩니다. 삭제하고 새로 생성하시겠습니까?')) {
        del(KeystoreKey);
        localStorage.clear();

        const privateKey = localStorage.getItem(userKey)
        console.log(['first init', privateKey]);
        if (privateKey) {
          console.log('있음')
        } else {
          // key pair 생성
          let keyPair = ec.genKeyPair(); // Generate random keys
          let privKey = keyPair.getPrivate("hex");
          let pubKey = keyPair.getPublic().x.toString('hex') + keyPair.getPublic().y.toString('hex');

          const publicKeyHash = keccak_256(Uint8Array.from(hexStringToArray(pubKey)));
          // const pubHash = publicKeyHash.substring(publicKeyHash.length);
          let address = publicKeyHash.substring(publicKeyHash.length - 40);
          console.log(['key pair', keyPair, privKey, address]);
          localStorage.setItem(deviceKey, privKey);  // key를 저장해둔다.

          // const param = {device_id: privKey, site_code: 'blockchain'};
          const param = {device_id: privKey, site_code: 'demo'};
          //사용자 등록
          const result = await postData('/user/registerDevice', param);
          console.log(['regiserDevice result', result]);
          if (result.data.success) {
            const user = result.data.info[0];
            localStorage.setItem(userKey, JSON.stringify(user));
          } else {
            console.error(['device reg error']);
          }
        }

        next();
      } else { 
        next(false);
      }
    }
  })
}
const authAddressAndPinNumber = (to, from, next) => {
  localKeystore.getAddress().then((address) => {
    if (!address) { next('/join'); } else {
      const pinNumber = sessionStorage.getItem(pinNumberKey)
      if (!pinNumber) {
	console.log('not found pin number')
	router.push({ path: '/login', query: {path: to.path}})
      } else {
	next()
      }
    }
  })
}

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    name: 'detail',
    path: '/event/:eventCode',
    component: () => import('@/views/Detail')
  },
  // {
  //   path: '/detail',
  //   components: {
  //     default: Detail,
  //     modal: Wallet
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       components: {
  //         default: Detail,
  //         modal: Wallet
  //       }
  //     },
  //     {
  //         path: 'wallet',
  //         components: {
  //           default: Detail,
  //           modal: Wallet
  //         }
  //     },
  //     {
  //       path: 'wallet-list',
  //       components: {
  //         default: Detail,
  //         modal: WalletList
  //       }
  //     },
  //  ],
  // },
  {
    path: '/wallet',
    component: Wallet,
    beforeEnter: authAddressAndPinNumber,
  },
  {
    path: '/wallet-list',
    component: WalletList
  },
  {
    path: '/wallet-make',
    component: WalletMake
  },
  {
    path: '/wallet-viewitem',
    component: WalletViewItem
  },
  {
    path: '/join',
    component: Join,
    beforeEnter: cofirmKeyRemoval,
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: authAddress,
  },
  {
    path: '/myhome',
    component: MyHome
  },
  {
    path: '/myinfo',
    component: MyInfo
  },
  {
    path: '/pin',
    component: Pin
  },
  {
    path: '/goodsHome',
    component: GoodsHome
  },
  {
    path: '/find',
    component: Find
  },
  {
    path: '/share',
    component: Share
  },
  // {
  //   path: '/test',
  //   components: {
  //     default: Home,
  //     modal: Wallet
  //   }
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
});

export default router


